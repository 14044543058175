@import url("https://fonts.googleapis.com/css2?family=Fira+Code&display=swap");

.control .icon {
  pointer-events: all;
}



$family-sans-serif: "Roboto", sans-serif;
$info: #b9e1dc;
$link: #7080da;
// $success: #49d67f;
$danger: #d45f46;
$body-background-color: #fbfbfb;
$footer-padding: 1.5rem 1.5rem 1.5rem;

@import "../node_modules/bulma/bulma.sass";

html,
body {
  min-height: 100vh;
}

.pointer {
  cursor: pointer;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#wrapper {
  flex: 1;
}